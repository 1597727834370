div.swal2-container {
  z-index: 9999;
}
.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
  line-height: unset !important;
  max-height: none !important;
  white-space: normal;
}

.MuiDataGrid-renderingZone {
  max-height: none !important;
}

.MuiDataGrid-row {
  max-height: none !important;
}
